<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'FormButtons',
  props: {
    formType: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      requerid: false,
    },
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const lastPath = router.options.history.state.back;
    const pathHome = '/';

    return {
      routerBack: () => {
        store.commit('frameworkNavegador/setBeingReturned', true);
        store.commit('painelPrograma/setPainels',[])
        if (!lastPath) {
          return router.push({ path: pathHome });
        }

        router.push({ path: lastPath });
      },
    };
  },
};
</script>
    
<template>
  <div class="d-flex flex-column flex-md-row">
    <slot></slot>
    <div class="d-flex text-end flex-column-reverse d-md-block flex-1">
      <a
        v-if="!inModal"
        @click="routerBack"
        class="btn btn-light border shadow-sm mt-1 mt-md-3 mx-sm-0 mx-md-3 col-12 col-md-3 col-lg-2"
        title="Voltar"
      >
        <i class="ri-arrow-go-back-fill align-bottom me-1"></i> Voltar
      </a>
      <button
        v-if="formType !== 'show' && !$props.loading"
        class="ms-auto mt-3 btn btn-primary shadow-sm col-12 col-md-6 col-lg-3"
        title="Salvar"
        type="submit"
      >
        <span
          ><i class="ri-save-2-fill align-bottom me-1"></i>
          {{ formType == "creation" ? "Criar" : "Salvar" }}
        </span>
      </button>
      <button
        v-if="formType !== 'show' && $props.loading"
        class="ms-auto mt-3 btn btn-primary btn-load shadow-sm col-12 col-md-6 col-lg-3"
      >
        <span class="d-flex align-items-center justify-content-center gap-2">
          <span class="spinner-border flex-shrink-0" role="status"></span>
          Carregando
        </span>
      </button>
    </div>
  </div>
</template>